<template>
  <div class="containter clearfix">
    <div class="float-right">
      <img class="image" src="@/assets/reachout.jpg" />
    </div>
    <h1 class="emboss">Drop A Line</h1>
    <p>
      Don’t be afraid to reach out! I’d love to hear from you, whether it has
      anything to do with my work or not. For press and professional inquiries,
      fan feedback, and miscellaneous jabber,
      <a href="mailto: info@genevieve-rose-vickers.com" target="_blank"
        >Email Me.</a
      >
    </p>
    <p>
      Special Thanks to
      <a href="https://jolieritchie.zenfolio.com" target="_blank"
        >Jolie Ritchie</a
      >
      for the photographs!
    </p>
  </div>
</template>

<style scoped>
@media (min-width: 601px) {
  h1 {
    margin-top: 120px;
    margin-left: 50px;
    text-align: center;
    line-height: 50px;
  }

  p {
    padding-top: 30px;
    padding-left: 50px;
    padding-right: 50px;
    padding-bottom: 0px;
  }

  .image {
    margin-top: 10px;
    margin-bottom: 50px;
    margin-left: 10px;
    margin-right: 10px;
  }
}
</style>
